import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import Faq from "./screens/faq/faq-list"

const FaqList = () => {
  return (
    <React.Fragment>
      <Faq />
    </React.Fragment>
  )
}
export default FaqList

export const Head = () => (
  <Seo
    title="FAQ"
    description="Frequently asked questions."
    image="/meta/about-us-faq.jpg"
  />
)
